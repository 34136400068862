import styled from "styled-components";
import base from "../../styles/base.style";

export const BannerWrap = styled('section')`
    ${base}; 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    .layer-wrap{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .layer{
            position: absolute;
            transition: .3s ease;
            &.layer-1,
            &.layer-2,
            &.layer-3{ 
                left: 0; 
            } 
            &.layer-4,
            &.layer-5,
            &.layer-6{
                right: 0; 
            } 
            &.layer-1{
                left: 72px;
                top: 0;
            }
            &.layer-2{
                top: 37px;
            }
            &.layer-3{
                top: 376px;
            }
            &.layer-4{
                top: 0;
            }
            &.layer-5{
                top: 307px;
            }
            &.layer-6{
                bottom: 0;
            }
        } 
    } 
    
    .bn-theme-name{
        color: white;
        font-weight: bold;
        line-height: normal;
        margin-bottom: 20px;
        span{
            color: #fa7a60;
        }
    }
    .bn-theme-sub{
        color: white;
        line-height: 1.3;
        font-weight: 600;
        &-2{
            color: white;
            line-height: normal;
            font-weight: 600; 
            span{
                color: #fa7a60;
            }
        }
    }
    
    .bn-btn{  
        font-weight: bold;
        font-size: 14px;
        line-height: 1; 
        color: #FFFFFF;
        display: inline-flex;
        justify-content: center;
        padding: 23px 40px;
        max-width: 225px;
        background: var(--second-color);
        border-radius: 30px;
        text-decoration: none;
        text-transform: uppercase;
        transition: .4s;
        box-shadow: 0px 6px 24px 0px rgba(249, 122, 96, 0.23); 
        @media screen and (max-width: 767px)   { 
            padding: 20px 40px;
        }
        &-wrap{
            margin-top: 40px;
        }
        &:hover{
            background: #0a6edd;
        }
        &-buy{
            background: #0a6edd;
            &:hover{
                background: var(--second-color);
            }
        }
    }
    .el-demo-wrap{
        .number {
            font-size: 50px;
            line-height: 1;
            font-weight: bold;
        }
        .text{
            font-size: 15px;
            line-height: 25px;
            color: white;
        }
        .el-animation{
            position:absolute;
            top: -20px;
            left: -20px;
            width: calc(100% + 40px);
            height: calc(100% + 40px);
            border-radius: 100%;
            z-index: -1;
            pointer-events: none;
        }
        .element-wrap,
        .demo-wrap{
            display: flex;
            flex-direction: column;
            width: 208px;
            height: 208px;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            position: relative;
            z-index: 1;
            cursor: pointer;
            @media screen and (max-width: 1199px) {
                width: 180px;
                height: 180px;
            }
            @media screen and (max-width: 480px)   {
                width: 150px;
                height: 150px;
            }
        }
        .element-wrap {
            background: #139d7d;
            position: absolute;
            top: -25%;
            left: -143px;
            @media screen and (max-width: 1440px) and (min-width: 1200px){
                left: 0;
                top: -20%;
            }
            @media screen and (max-width: 1199px) and (min-width: 992px){
                left: 0;
                top: -25%;
            }
            @media screen and (max-width: 991px) and (min-width: 768px)  {
                left: 0;
                top: -10%;
            }
            @media screen and (max-width: 767px)   {
                left: calc(50% - 180px / 2 );
                top: -5%;
                transform: translateX(-50%);
            }
            @media screen and (max-width: 480px)   {
                left: calc(50% - 150px / 2 );
                top: 10%;
            }
            .el-animation{
                background: rgba(19,157,125,0.25);
            }
            .number {
                color: #a2ff72;
            }
        }
        .demo-wrap {
            background: #0a6edd; 
            position: absolute;
            top: -50%;
            left: 40px;
            @media screen and (max-width: 1440px) and (min-width: 1200px){
                left: 180px;
                top: -40%;
            }
            @media screen and (max-width: 1199px) and (min-width: 992px){
                left: 160px;
                top: -30%;
            }
            @media screen and (max-width: 991px) and (min-width: 768px) {
                left: 160px;
                top: -15%;
            }
            @media screen and (max-width: 767px)  {
                left: calc(50% - 15px);
                top: 15%;
            }
            @media screen and (max-width: 480px)   {
                left: calc(50% - 5px); top: 25%;
            }
            .el-animation{
                background: rgba(10,110,221,0.25);
            }
            .number {
                color: #fff600;
            }
        }
    }
`;

export const BgBannerWrap = styled('div')`
    ${base}; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img{
        height: 100%;
        object-fit: cover;
        width: 100%;
    } 
`;

