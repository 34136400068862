import {Div, GlobalHeading, Heading, Img, SubHeading} from "../../styles/base.style";
import React from "react";
import {Special_Data} from "../../pages/datas/Home";
import PropTypes from "prop-types";
import {CoreFeaturesWrap, SpecialItem} from "./coreFeatures.style";
import ScrollAnimation from "react-animate-on-scroll";

const CoreFeatures = ({specialItem}) => {
    return (
        <CoreFeaturesWrap id="special" pt={115} pb={[120]}>
            <Div className="container-fluid" maxWidth={1570}>
                <Div className="row">
                    <Div className="col-lg-6 offset-lg-3 text-center">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <GlobalHeading>What's Special In Ametex</GlobalHeading>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                        <SubHeading mb={0}>We have tried to include a lot of features to make Ametex the most flexible
premium WordPress theme on the market</SubHeading>
                    </ScrollAnimation>
                    </Div>
                </Div>
                <Div className="row">
                    <Div className="col-md-12">
                        <Div
                            className="special-wrap"
                            mt={56}
                            display={'grid'}
                            gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']}
                            gridGap={['30px', '30px', '30px', '20px', '30px']}>
                            {Special_Data.map((item, index) => (
                                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100 * index} key={`special-${index}`}>
                                    <SpecialItem {...specialItem} className="special-item text-center" title={item.title}>
                                        <Div position={'relative'} height={130} pt={[40]} pb={[30]}><Img maxHeight={'100%'} src={item.icon} alt={item.title} /></Div>
                                        <Div position={'relative'} pt={45} pb={55} pl={[50, 40, 30, 20, 54]} pr={[50, 40, 30, 20, 54]}>
                                            <Heading className="title" mb={25} fontWeight={700} fontSize={20} lineHeight={'28px'} color={'var(--theme-color)'} as={'h3'}>{item.title}</Heading>
                                            <Div className="des" fontSize={16} lineHeight={'28px'} color={'#716886'}>{item.des}</Div>
                                        </Div>
                                    </SpecialItem>
                                </ScrollAnimation>
                                )
                            )}
                        </Div>
                    </Div>
                </Div>
            </Div>
        </CoreFeaturesWrap>
    );
};


CoreFeatures.propTypes = {
    specialItem: PropTypes.object,
};
CoreFeatures.defaultProps = {
    specialItem: {}
};
export default CoreFeatures
