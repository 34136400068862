import React, {useEffect, useState} from 'react';
import {Div, Heading, Img, Link} from "../../styles/base.style";
import Banner_BG from '../../images/bg-banner.jpg'
import layer_1 from '../../images/layer-1.png'
import layer_2 from '../../images/layer-2.png'
import layer_3 from '../../images/layer-3.png'
import layer_4 from '../../images/layer-4.png'
import layer_5 from '../../images/layer-5.png'
import layer_6 from '../../images/layer-6.png'
import img_mockup from '../../images/banner-mockup.png'
import {BannerWrap} from "./bannerSection.style";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

function getSize() {
    return {
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        outerHeight: window.outerHeight,
        outerWidth: window.outerWidth,
    };
}

function useWindowSize() {
    let [windowSize, setWindowSize] = useState(getSize());

    function handleResize() {
        setWindowSize(getSize());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}

const scrollIt = element => {
    window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': element.offsetTop
    });
};


const Banner = ({...props}) => {
    const textEditor = props.textEditor ? props.textEditor : {};
    const purchase_link = props.purchase_link ? props.purchase_link : '#';
    const size = useWindowSize();

    const layer = document.querySelectorAll('.layer');
    let initT = [...layer].map(function (el, i) {
            return el.offsetTop;
        }
    );
    window.requestAnimationFrame = window.requestAnimationFrame
        || window.mozRequestAnimationFrame
        || window.webkitRequestAnimationFrame
        || window.msRequestAnimationFrame
        || function (f) {
            setTimeout(f, 1000 / 60)
        };

    function parallaxLayers() {
        let scrollTop = window.pageYOffset;
        layer.forEach(function (currentValue, index, arr) {
            currentValue.style.top = (-scrollTop * .5 + initT[index]) + 'px';
        })
    }

    window.addEventListener('scroll', function () {
        //requestAnimationFrame(parallaxLayers)
    }, false);
    return (
        <BannerWrap id="banner" pt={[170, 170, 220, 270]} pb={[155, 155, 200, 255]} backgroundImage={'url(' + Banner_BG + ')'}>
            {size.innerWidth > 1200 ? (
                <Div className="layer-wrap">
                    <>
                        <Div className="layer layer-1"><Img src={layer_1} alt="layer 1" /></Div>
                        <Div className="layer layer-2"><Img src={layer_2} alt="layer 2" /></Div>
                        <Div className="layer layer-3"><Img src={layer_3} alt="layer 3" /></Div>
                        <Div className="layer layer-4"><Img src={layer_4} alt="layer 4" /></Div>
                        <Div className="layer layer-5"><Img src={layer_5} alt="layer 5" /></Div>
                        <Div className="layer layer-6"><Img src={layer_6} alt="layer 6" /></Div>
                    </>
                </Div>
            ) : ''}
            <Div className="container">
                <Div className="row align-items-center" mb={60}>
                    <Div className="col-lg-6 text-center">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={300}>
                            <Div className="laptop" ml={['', '', '', '-25px']} mr={['', '', '', '-25px']} mb={['30px', '30px', '30px', '0px']}>
                            <Img src={img_mockup} alt="mockup" />
                            </Div>
                        </ScrollAnimation>
                    </Div>
                    <Div className="col-lg-6 text-center">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={500}>
                            <Heading className="bn-theme-name" fontSize={['60px', '80px']}>Ametex<span>.</span></Heading>
                            <Heading className="bn-theme-sub" fontSize={['18px', '24px']}>The Best Premium Multipurpose</Heading>
                            <Heading className="bn-theme-sub-2" fontSize={['34px', '37px']}>WordPress Theme of <span>{new Date().getFullYear()}</span></Heading>
                        </ScrollAnimation>

                        <Div className="bn-btn-wrap" display={'inline-grid'} gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={['10px']}>
                            <ScrollAnimation animateIn="tada" animateOnce={true} delay={200}>
                                <Link className="bn-btn bn-btn-demo" href="#" onClick={(e) => {
                                    e.preventDefault();
                                    scrollIt(document.getElementById('prebuild-demo'));
                                }}>View Live Demo</Link>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="tada" animateOnce={true} delay={200}>
                                <Link className="bn-btn bn-btn-buy" href={purchase_link}>Buy this Theme</Link>
                            </ScrollAnimation>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </BannerWrap>
    );
};
// Footer style props
Banner.propTypes = {};
Banner.defaultProps = {};
export default Banner;
