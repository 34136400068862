import Powerful_admin_pannel_Icon from '../../../images/special/admin.png'
import Click_Demo_Icon from '../../../images/special/import.png'
import Fully_Customizable_Icon from '../../../images/special/perfoman.png'
import Shortcode_Icon from '../../../images/special/mutil-lg.png'
import Mega_Menu_Icon from '../../../images/special/menu.png'
import Responsive_Icon from '../../../images/special/responsive.png'
import Sidebar_Icon from '../../../images/special/ajax.png'
import Updates_Icon from '../../../images/special/demo.png'
import Typography_Icon from '../../../images/special/seo.png'
import Colors_Icon from '../../../images/special/styles.png'
import Backgrounds_Icon from '../../../images/special/mutil-lg.png'
import Speed_Icon from '../../../images/special/lazy.png'
//================
import Support_Icon from '../../../images/support.svg'
import Document_Icon from '../../../images/document.svg'

export const Special_Data = [
    {
        title: '1 Click Demo Import',
        icon: Click_Demo_Icon,
        des: 'Install demo site with just one click.',
    },
    {
        title: 'Powerful admin pannel',
        icon: Powerful_admin_pannel_Icon,
        des: 'Friendly & gorgeous options panel.',
    },
    {
        title: 'Fully Customizable',
        icon: Fully_Customizable_Icon,
        des: 'Change everything on the site easily.'
    },
    {
        title: 'Shortcode generator',
        icon: Shortcode_Icon,
        des: 'Custom elements for the WPBakery.',
    },
    {
        title: 'Built-in-Mega Menu',
        icon: Mega_Menu_Icon,
        des: 'Custom elements for the WPBakery.',
    },
    {
        title: 'Responsive Retina Ready',
        icon: Responsive_Icon,
        des: 'Always looks perfect on any screens.',
    },
    {
        title: 'Sidebar Widgets',
        icon: Sidebar_Icon,
        des: 'Always looks perfect on any screens.',
    },
    {
        title: 'Auto Updates',
        icon: Updates_Icon,
        des: 'Always looks perfect on any screens.',
    },
    {
        title: 'Good Speed & Performance',
        icon: Speed_Icon,
        des: 'Always looks perfect on any screens.',
    },
    {
        title: 'Advanced Typography',
        icon: Typography_Icon,
        des: 'Always looks perfect on any screens.',
    },
    {
        title: 'Unlimited Colors',
        icon: Colors_Icon,
        des: 'Always looks perfect on any screens.',
    },
    {
        title: 'Custom Backgrounds',
        icon: Backgrounds_Icon,
        des: 'Always looks perfect on any screens.',
    },
];


export const Json_Demo = {
    "status": "ok",
    "response": {
        "prebuild_demo_layout": "layout-3-col",
        "enable_filter_demo_homepage": false,
        "cat_filter": {
            "all": "All",
            "creative": "Creative",
            "portfolio": "Portfolio",
            "services": "Services",
            "corporate": "Corporate",
            "onepage": "One Page"
        },
        "demo_page": [
            {
                "image_featured": "images/home/ametex-home-1.jpg",
                "name_demo_page": "Ametex Home Page 1",
                "link_demo_page": "https://ametex.beplusthemes.com/",
                "category_filter": {
                    "all": "All",
                    "creative": "Creative",
                    "portfolio": "Portfolio",
                    "services": "Services"
                },
                "new_label": false,
                "coming_soon": false
            },
            {
                "image_featured": "images/home/ametex-home-2.jpg",
                "name_demo_page": "Ametex Home Page 2",
                "link_demo_page": "https://ametex.beplusthemes.com/home-page-02/",
                "category_filter": {
                    "all": "All",
                    "corporate": "Corporate",
                    "creative": "Creative",
                    "portfolio": "Portfolio",
                    "services": "Services"
                },
                "new_label": false,
                "coming_soon": false
            },
            {
                "image_featured": "images/home/ametex-home-3.jpg",
                "name_demo_page": "Ametex Home Page 3",
                "link_demo_page": "https://ametex.beplusthemes.com/home-page-03/",
                "category_filter": {
                    "all": "All",
                    "creative": "Creative",
                    "portfolio": "Portfolio"
                },
                "new_label": false,
                "coming_soon": false
            },
            {
                "image_featured": "images/home/ametex-home-4.jpg",
                "name_demo_page": "Ametex Home Page 4",
                "link_demo_page": "https://ametex.beplusthemes.com/home-page-04/",
                "category_filter": {
                    "all": "All",
                    "corporate": "Corporate",
                    "services": "Services"
                },
                "new_label": false,
                "coming_soon": false
            },
            {
                "image_featured": "images/home/ametex-home-5.jpg",
                "name_demo_page": "Ametex Home Page 5",
                "link_demo_page": "https://ametex.beplusthemes.com/home-page-05/",
                "category_filter": {
                    "all": "All",
                    "portfolio": "Portfolio",
                    "services": "Services"
                },
                "new_label": false,
                "coming_soon": false
            },
            {
                "image_featured": "images/home/ametex-home-6.jpg",
                "name_demo_page": "Ametex Home Page 6",
                "link_demo_page": "https://ametex.beplusthemes.com/home-page-06/",
                "category_filter": {
                    "all": "All",
                    "corporate": "Corporate",
                    "creative": "Creative"
                },
                "new_label": false,
                "coming_soon": false
            },
            {
                "image_featured": "images/home/ametex-home-7.jpg",
                "name_demo_page": "Ametex Home Page 7",
                "link_demo_page": "https://ametex.beplusthemes.com/home-page-07/",
                "category_filter": {
                    "all": "All",
                    "onepage": "One Page",
                    "portfolio": "Portfolio"
                },
                "new_label": false,
                "coming_soon": false
            }
        ],
        "general": {
            "document_link": "https://bearsthemespremium.com/document/Ametex/",
            "support_link": "https://bearsthemes.ticksy.com/",
            "purchase_link": "https://1.envato.market/eZNQD"
        }
    }
}
