import React from "react";
import anime from "animejs";
import {FilterWrap, HomeDemoMetaWrap, HomeDemoWrap, HomepageDemoContainer, ImgWrap} from "../prebuildDemoSection.style";
import {Div, Heading, Img, Link} from "../../../styles/base.style";
import {Flipped, Flipper} from "react-flip-toolkit";
// import {LazyLoadImage} from "react-lazy-load-image-component";
// import 'react-lazy-load-image-component/src/effects/opacity.css';
// import 'react-lazy-load-image-component/src/effects/black-and-white.css';
// import 'react-lazy-load-image-component/src/effects/blur.css';
import New_Icon from '../../../images/new-item.svg';

class HomepageDemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            homePageDemo: [],
            homePageTemp: [],
            classActive: 'all',
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {isGetApi} = props;
        if (!isGetApi) {
            return props;
        }
        return null
    }

    _onStart = (el) => {
        console.log('on start');
    };

    _onComplete = (el) => {
        console.log('on complete');
    };

    _shouldFlip = (prev, current) => {
        console.log('should flip', prev, current);
    };

    _onAppear = (el, index) => {
        console.log('_onAppear');
        anime({
            targets: el,
            opacity: [0, 1],
            scale: [0, 1],
            easing: "easeOutSine",
            delay: index * 40,
            duration: 400
        })
    };

    _onExit = (el, index, removeElement) => {
        console.log('_onExit');
        anime({
            targets: el,
            opacity: 0,
            scale: 0.5,
            easing: "easeOutSine",
            duration: 400,
            delay: index * 40,
            complete: removeElement
        })
    };

    _filterHomePage(e, filter) {
        e.preventDefault();
        const has = Object.prototype.hasOwnProperty;

        let homePages = this.state.homePageTemp.reduce((dataHomeNew, home, index, dataHome) => {
            let _has = has.call(home.category_filter, filter);
            console.log(_has, dataHomeNew, home, index);
            if (_has) dataHomeNew[index] = home;

            return dataHomeNew;
        }, []);
        console.log(homePages)
        this.setState({homePageDemo: homePages, classActive: filter});
    }

    _renderFilterHomePage() {
        let {catFilter} = this.state;
        if (catFilter) {
            catFilter = Object.entries(catFilter);
            return (
                <FilterWrap display={'flex'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'} mb={75}>
                    {catFilter.map((cat, index) => {
                        let filterClass = ['filter-nav'];
                        if (cat[0]) {
                            filterClass.push(cat[0]);
                            if (cat[0] === this.state.classActive) {
                                filterClass.push('active');
                            }
                        }
                        if (cat[1]) {
                            return (
                                <Link onClick={e => this._filterHomePage(e, cat[0])}
                                      href={'#'}
                                      key={'filter-nav-' + index}
                                      className={filterClass.join(" ")}
                                      data-filter={cat[0]}>
                                {cat[1]}
                            </Link>
                            )
                        }
                        return false;
                    })}
                </FilterWrap>
            );
        }
    }

    _renderDemoHomePage() {
        let homePageDemo = this.state.homePageDemo;
        if (homePageDemo) {
            return (
                <HomepageDemoContainer
                    className={this.state.prebuild_demo_layout}
                    display={'grid'}
                    gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']}
                    gridGap={'60px 30px'}>
                    {homePageDemo.map((home, index) => {
                        return <Flipped
                            key={`home-${index}`}
                            flipId={`home-${index}`}
                            spring={'veryGentle'}

                            onStartImmediate={this._onStart}
                            onComplete={this._onComplete}
                            onAppear={this._onAppear}
                            onExit={this._onExit}
                            shouldInvert={this._shouldFlip}
                        >
                            <HomeDemoWrap data-filter={Object.keys(home.category_filter).join(' ')}>
                                {(home.new_label) ?
                                    <Img src={New_Icon} alt={'new icon'} position={'absolute'} top={0} left={-9} zIndex={9} /> : ''}
                                {(home.image_featured) ?
                                    <ImgWrap>
                                        <Link href={(home.link_demo_page) ? home.link_demo_page : '#'} target={'_blank'}>
                                            <Img src={home.image_featured} alt={home.name_demo_page} />
                                        </Link>
                                    </ImgWrap>
                                    : ''
                                }
                                <HomeDemoMetaWrap>
                                    <Link href={(home.link_demo_page) ? home.link_demo_page : '#'} target={'_blank'}>
                                        <Heading className={'home-title'} fontSize={['20px', '22px']}>
                                            {home.name_demo_page}
                                        </Heading>
                                    </Link>
                                    <Link className={'demo-link'} href={(home.link_demo_page) ? home.link_demo_page : '#'} target={'_blank'}>VIEW DEMO</Link>
                                </HomeDemoMetaWrap>
                            </HomeDemoWrap>
                        </Flipped>;
                    })}
                </HomepageDemoContainer>
            );
        }
    }

    renderHomePageDemo() {
        return (
            <Flipper flipKey={this.state.homePageDemo}>
                {this.state.enable_filter ? this._renderFilterHomePage() : ''}
                {this._renderDemoHomePage()}
            </Flipper>
        );
    }

    render() {
        const {isLoading, error} = this.props;
        return (
            <Div>
                {error ? <p className={'text-center'}>{error}</p> : null}
                {!isLoading ?
                    (this.renderHomePageDemo()) :
                    (<p className={'text-center'}>Loading...</p>)
                }
            </Div>
        )
    }
}

export default HomepageDemo
