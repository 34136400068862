import styled from "styled-components";
import base from "../../styles/base.style";

const HomeDemoMetaWrap = styled('div')`
    ${base}; 
    width: 100%;
    transition: .4s;
    padding: 40px 40px 0; 
    a{
        text-decoration: none;
    }
    .home-title{ 
        color: var(--theme-color); 
        position: relative;
        line-height: 1.3;
        &:before{
            content: '';
            height: 2px;
            width: 50px;
            background: var(--theme-color);
            left: 0;
            bottom: -5px;
            position: absolute;
            transition: .3s;
        }
        &:hover{
            &:before{
                width: 100px;
            }
        }
    }
    .demo-link{
        display: none;
    }
`
const ImgWrap = styled('div')`
    ${base}; 
        overflow: hidden;
        box-shadow: 0px 0 57px rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        transition: .4s;
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    &:hover{
        box-shadow: 0px 0 57px rgba(0,0,0,0.23);
    }
`
const HomeDemoWrap = styled('div')`
    ${base};
    position: relative; 
    cursor: pointer;
    //height: 70vh;
    //max-height: 450px;
    ${ImgWrap}{
    } 
    &:hover{
        ${HomeDemoMetaWrap}{ 
            .home-title{
                &:before{
                    width: 100px;
                }
            }
        } 
    }
`
const FilterWrap = styled('div')`
    ${base};
    .filter-nav{ 
        font-size: 18px;
        text-decoration: none;  
        color: black;
        padding: 16px 40px;
        line-height: 1;
        background: #ECEAEA;
        border-radius: 25px; 
        font-weight: normal;
        transition: .4s;
        min-width: 150px;
        text-align: center;
        margin: 0 5px 10px;
        &:hover,
        &.active{
            background: var(--theme-color);
            color: white;
        }
    }
`;
const HomepageDemoContainer = styled('div')`
    ${base};
    &.layout-4-col{ 
        @media screen and (min-width: 1024px){
            grid-template-columns: 1fr 1fr 1fr 1fr; 
            grid-gap: 50px 35px;
        }
        ${HomeDemoWrap}{ 
            height: auto;
            max-height: 850px;
            box-shadow: none;
            overflow: visible;
            background: transparent;
            &:before{
                content: unset;
            }
            ${ImgWrap}{
                height: calc(100% - 120px);
                box-shadow: 0px 19px 16px 0px rgba(24,24,40,0.4);    
                border-radius: 3px;
                overflow: hidden;
                position: relative; 
                
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(80, 94, 216, 0.5);
                    top: 0;
                    left: 0;
                    opacity: 0; 
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
                }
            }
            ${HomeDemoMetaWrap}{
                position: relative;
                top: unset;
                left: unset;
                transform: none;
                opacity: 1;
                visibility: visible;
                padding: 40px 20px 0;
                text-align: center;
                .home-title{
                    color: black;
                    margin-bottom: 0;
                    position: relative;
                    display: inline-block;
                    font-size: 18px;
                    &:before {
                        content: '';
                        height: 1px;
                        width: 50px;
                        background: #505ed8;
                        position: absolute;
                        bottom: -2px;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: all 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
                    }
                }
                .demo-link{
                    display: none;
                }
            }
            &:hover{
                ${ImgWrap}{
                    &:after { 
                        opacity: 1; 
                        visibility: visible; 
                    }
                } 
                ${HomeDemoMetaWrap}{ 
                    .home-title{ 
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.layout-3-col-full{
        ${HomeDemoWrap}{
            overflow: unset;
            box-shadow: none;
            cursor: pointer;
            height: auto;
            max-height: none;
            background: transparent;
            &:before{
                content: unset;
            }
        }
        ${ImgWrap}{
            height: 320px; 
            overflow: hidden;
            position: relative;
            box-shadow: 0 8px 11px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            transition: .3s;
            img{
                width: 100%;
                height: auto;
                object-fit: unset;
            }
            a{
                display: block;
            } 
        }
        ${HomeDemoMetaWrap}{
            position: relative;
            top: unset;
            left: unset;
            transform: none;
            opacity: 1;
            visibility: visible;
            padding: 20px 20px 0;
            text-align: center;
            .home-title{
                color: black;
                margin-bottom: 0;
                position: relative;
                display: inline-block;
                font-size: 18px;
                &:before {
                    content: '';
                    height: 1px;
                    width: 50px;
                    background: #505ed8;
                    position: absolute;
                    bottom: -2px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
                }
            }
            .demo-link{
                display: none;
            }
        }
        ${HomeDemoWrap}{
            &:hover{
                ${ImgWrap}{
                    transform: translateY(-15px);    
                    box-shadow: 0 29px 40px rgba(0,0,0,.5);
                }
            }
        }
    }
    `
;
export {HomepageDemoContainer, HomeDemoWrap, HomeDemoMetaWrap, FilterWrap, ImgWrap}
