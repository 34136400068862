import styled from "styled-components";
import base from "../../styles/base.style";
import BG_DOC from '../../images/bg-doc.jpg'

export const DetailedStepsWrap = styled('section')`
${base};
padding-top: 110px;
padding-bottom: 95px;
background-image: url("${BG_DOC}");
background-position: center;
background-repeat: no-repeat;
background-attachment: fixed;
background-size: cover;
position: relative;
 @media (min-width: 1200px){
    .container {
        max-width: 1340px;
    }
}
&:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: -moz-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%);
    background-image: -webkit-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%);
    background-image: -ms-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%); 
    background-image: linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%); 
    opacity: .9;
}
.text-a{
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    line-height: 1;
    border-radius: 100%;
    font-size: 40px;
    font-weight: bolder;
    color: #0a6edd;
}
.left-wrap{
    display: grid;
    grid-template-columns: 84px auto;
    grid-gap: 30px;
    @media (max-width: 520px){
        grid-template-columns: auto; 
        text-align: center;
        .text-a{
            margin: auto;
        }
    }
}
.right-wrap{
    grid-gap: 10px;
    display: grid;
    grid-template-columns: auto;
    text-align: center;
    @media (min-width: 1280px){ 
        display: inline-grid;
        grid-template-columns: auto auto;
    }
    @media (min-width: 520px) and (max-width: 767px){ 
        display: inline-grid;
        grid-template-columns: auto auto;
    }
    @media (max-width: 767px){ 
        margin-top: 30px;
    }
    .btn-contact,
    .btn-document{
        padding: 23px 40px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        white-space: nowrap;
        line-height: 1;
        font-size: 14px;
        display: inline-flex;
        text-decoration: none;
        border-radius: 30px; 
        box-shadow: 0px 6px 24px 0px rgba(249, 122, 96, 0.23); 
        transition: .4s;
        background: #0642d0;
        &:hover{
            background: var(--second-color);
        }
    }
    .btn-document{ 
        background: var(--second-color);
        &:hover{
            background: #0642d0;
        }
    }
}
`;
