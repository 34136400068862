import {Div, GlobalHeading, Section, SubHeading} from "../../styles/base.style";
import React, {Component} from "react";
import HomepageDemo from "./HomepageDemo";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

class PrebuildDemoSection extends Component<{}> {
    render() {
        let {props} = this;
        return (
            <Section id="prebuild-demo" pt={108} pb={144}>
            <Div className="container">
                <Div className="row">
                    <Div className="col-md-8 offset-md-2 text-center">
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                            <GlobalHeading>Some Examples of What You Can do with Ametex.</GlobalHeading>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                            <SubHeading fontSize={['18px !important','20px !important']}>Powerful and flexible wordpress theme that comes with 30 unique and stunning demos, suitable for all your idea !</SubHeading>
                        </ScrollAnimation>
                    </Div>
                </Div>
            </Div>
            <Div mt={50} className={(props.prebuild_demo_layout === 'layout-3-col') ? 'container' : "container-fluid"}>
                <Div className="row">
                    <Div className="col-md-12">
                        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <HomepageDemo {...props} />
                        </ScrollAnimation>
                    </Div>
                </Div>
            </Div>
        </Section>
        );
    }
}

export default PrebuildDemoSection
