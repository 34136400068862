import styled from "styled-components";
import base from "../../styles/base.style";

export const PremiumPluginsWrap = styled('section')`
${base};
padding: 115px 0 125px;
background: #f5f5f5;
.plugins-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -20px;
    >div{
        flex: 0 0 33.333333%;
        max-width: 33.333333%; 
        @media (max-width: 991px){
            flex: 50%;
            max-width: 50%;
        }
        @media (max-width: 575px){
            flex: auto;
            max-width: 100%;
        }
    }
    @media (max-width: 575px){
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px 20px; 
        margin-right: 0; 
    }
    .pl-item{
        display: grid;
        grid-template-columns: 115px 1fr;
        background: white;
        border-radius: 10px; 
        overflow: hidden;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer; 
        position: relative;
        @media (min-width: 576px) and (max-width: 767px){ 
            grid-template-columns: auto;
        }
        @media (max-width: 575px){
            margin: 0; 
        }
        .pl-icon{
            align-items: center;
            justify-content: center;
            display: flex;
            @media (min-width: 576px) and (max-width: 767px){ 
                height: 115px;
            }
        }
        .pl-title{
            font-weight: bold;
            font-size: 20px;
            color: var(--theme-color);
            transition: .4s;
            @media (min-width: 576px) and (max-width: 767px){ 
                text-align: center;
            }
        }
        .pl-price{
            font-weight: 500;
            transition: .4s;
            span{
                color: var(--second-color);
            }
            @media (min-width: 576px) and (max-width: 767px){ 
                text-align: center;
            }
        }
        &:before{
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            width: 115px;
            height: 100%;
            background-image: -moz-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%); 
            background-image: -webkit-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%); 
            background-image: -ms-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%);
            transition: .4s;
            @media (min-width: 576px) and (max-width: 767px){ 
                height: 115px;
                width: 100%;
            }
        }
        &:hover{  
            .pl-title,
            .pl-price{
                color: white;
            }
            &:before{
                width: 100%;
                @media (min-width: 576px) and (max-width: 767px){ 
                    height: 100%;
                }
            }
        }
    }
}
`;
