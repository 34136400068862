import React from "react";
import {DetailedStepsWrap} from "./detailedSteps.style";
import {Div, Heading, Link, Text} from "../../styles/base.style";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

const DetailedSteps = (props) => {
    const doc_link = props.doc_link ? props.doc_link : '#';
    const sp_link = props.sp_link ? props.sp_link : '#';
    return (
        <DetailedStepsWrap id="detailed-steps">
            <Div className="container">
                <Div className="row align-items-center">
                    <Div className="col-md-7">
                        <Div className="left-wrap" alignItems={'center'}>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={600}>
                            <Text as={'span'} className="text-a">A</Text>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={100}>
                            <Div>
                                <Heading fontSize={36} color={'white'} fontWeight={700} lineHeight={1.2}>Detailed Steps Instructions</Heading>
                                <Text mb={0} fontSize={18} color={'white'} fontWeight={500} lineHeight={'26px'}>Detailed steps Instructions about how to set up, use and config accessible via theme option panels.</Text>
                            </Div>
                            </ScrollAnimation>
                        </Div>
                    </Div>
                    <Div className="col-md-5 text-right">
                        <Div className="right-wrap">
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={400}>
                            <Link href={sp_link} className="btn-contact">Contact Us Now</Link>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInRight" animateOnce={true} delay={600}>
                            <Link href={doc_link} className="btn-document">Online Documentation</Link>
                            </ScrollAnimation>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </DetailedStepsWrap>
    );
};

export default DetailedSteps
