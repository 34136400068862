import base from "../../styles/base.style";
import styled from "styled-components";

export const CoreFeaturesWrap = styled('section')`
    ${base};
`;

export const SpecialItem = styled('div')`
    ${base};
    height: 100%;
    transition: .3s;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgb(253, 253, 253);
    box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow:hidden;
 
    .title{
        text-transform: uppercase;
        transition: .4s color;
    }
    .des{
        transition: .4s color;
    }
    &:before{
        content: '';
        background-image: -moz-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%);
        background-image: -webkit-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%);
        background-image: -ms-linear-gradient( 103deg, rgb(10,110,221) 0%, rgb(0,194,130) 100%);
        position: absolute;
        height: 130px;
        width: 100%;
        transition: .4s height;
        top: 0;
        left: 0;
    }
    &:hover{
        border-color: var(--theme-color);
        box-shadow: 0  4px 22px rgba(0, 0, 0, 0.25);
        
        .title{
            color: white;
        }
        .des{
            color: white;
        }
        &:before{
            height: 100%;
        }
    }
`;
