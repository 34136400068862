import styled from "styled-components";
import base from "../../styles/base.style";
import BG from '../../images/bg-ft.jpg'

export const FooterSectionWrap = styled('section')`
    ${base};
    padding: 155px 0 165px;
    background-image: url("${BG}");
    background-color: #0a6edd;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 767px){ 
        padding: 100px 0  ;
    }

    .bn-btn{
        font-size: 15px;
        font-weight: bold;
        line-height: 1; 
        color: #FFFFFF;
        display: inline-flex;
        padding: 25px 65px;
        background: var(--second-color);
        border-radius: 36px;
        text-decoration: none;
        text-transform: uppercase;
        transition: .3s;
        box-shadow: 0px 6px 24px 0px rgba(249, 122, 96, 0.23); 

        &:hover{
            background: var(--theme-color);
        }
    }
`;
