import React from "react";
import {Div, Heading, Link, Text} from "../../styles/base.style";
import {FooterSectionWrap} from "./footerSection.style";
import ScrollAnimation from "react-animate-on-scroll";

const FooterSection = ({...props}) => {
    // console.log(props);
    const purchase_link = props.purchase_link ? props.purchase_link : '#';
    return (
        <FooterSectionWrap id="ftss">
            <Div className="container">
                <Div className="row">
                    <Div className="col-md-8 offset-md-2 text-center">
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                            <Text fontWeight={600} lineHeight={'24px'} fontSize={18} color={'#fff'} mb={20}>There is no limit to what you can create</Text>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                            <Heading fontSize={40} fontWeight={800} lineHeight={'55px'} color={'#fff'} mb={50}>Discover the whole world of new Possibilities with Ametex.</Heading>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={400}>
                            <Link href={purchase_link} className={'bn-btn'}>Purchase now</Link>
                        </ScrollAnimation>
                    </Div>
                </Div>
            </Div>
        </FooterSectionWrap>
    )
};

export default FooterSection;
