import {Div, GlobalHeading, Heading, Img, SubHeading, Text} from "../../styles/base.style";
import React from "react";
import {PremiumPluginsWrap} from "./premiumPlugins.style";

import ICON_VS from '../../images/vs-icon.png'
import ICON_EL from '../../images/ic-elementor.png'
import ICON_RS from '../../images/rs-icon.png'
import ICON_LS from '../../images/la-icon.png'
import ICON_BP from '../../images/buddy-icon.png'
import ICON_WOO from '../../images/woo-icon.png'
import ScrollAnimation from "react-animate-on-scroll";

const PLUGINS = [
    {
        icon: ICON_RS,
        title: 'Slider Revolution',
        price: '$25',
    },
    {
        icon: ICON_LS,
        title: 'Layer Slider',
        price: '$25',
    },
    {
        icon: ICON_EL,
        title: 'Elementor Builder',
        price: '$25',
    },
    {
        icon: ICON_WOO,
        title: 'WooCommerce',
        price: '$25',
    },
    {
        icon: ICON_BP,
        title: 'Buddy Press',
        price: '$25',
    },
];
const PremiumPlugins = () => (
    <PremiumPluginsWrap id="plugins">
        <Div className="container">
            <Div className="row">
                <Div className="col-md-12 text-center">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <GlobalHeading>What You Get for <span>$59</span></GlobalHeading>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
                        <SubHeading>Hexo is the perfect and cheapest solution for launching your online store. </SubHeading>
                    </ScrollAnimation>
                </Div>
            </Div>
            <Div className="row align-items-md-center flex-md-row flex-column-reverse" mt={50}>
                <Div className="col-md-12">
                    <Div className="plugins-grid">
                        {
                            PLUGINS.map((plugin, index) => (
                                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={150 * index} key={'plugin-' + index}>
                                <Div className="pl-item">
                                    <Div zIndex='2' className="pl-icon">
                                        <Img src={plugin.icon} alt={plugin.title} />
                                    </Div>
                                    <Div zIndex='2' padding={['40px 20px', '40px 30px','40px 20px', '40px 20px', '40px 30px']}>
                                        <Heading mb={14} className="pl-title">{plugin.title}</Heading>
                                        <Text mb={0} className="pl-price">{plugin.price} <span>free</span></Text>
                                    </Div>
                                </Div>
                                </ScrollAnimation>
                            ))
                        }
                    </Div>
                </Div>
            </Div>
        </Div>
    </PremiumPluginsWrap>
);

export default PremiumPlugins;
